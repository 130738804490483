import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from 'Client/common/actions/userActions';
import CreateBareMetalProjectProvidersSelection from './CreateBareMetalProjectProvidersSelection__JsBridge.res.js';

const mapDispatchToProps = dispatch => ({
  reduxfunction: {
    setUserData: userData => {
      dispatch(setUserData(userData));
    },
  },
});

const CreateBareMetalProjectProvidersSelectionContainer = connect(
  null,
  mapDispatchToProps,
)(CreateBareMetalProjectProvidersSelection);

export default ({ context, ...props }) => (
  <ReduxProvider>
    <CreateBareMetalProjectProvidersSelectionContainer
      projectId={props.projectId}
      providers={props.providers}
      railsContext={context}
      selectedProvidersIds={props.selectedProvidersIds}
      status={props.status}
      reduxfunction={props.reduxfunction}
    />
  </ReduxProvider>
);
