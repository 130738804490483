// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../libs/ID.res.js";
import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Provider from "../../../../models/Provider.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as CreateBareMetalProjectProvidersSelection from "./CreateBareMetalProjectProvidersSelection.res.js";

function CreateBareMetalProjectProvidersSelection__JsBridge$default(props) {
  var status = props.status;
  var selectedProvidersIds = props.selectedProvidersIds;
  var projectId = props.projectId;
  var providers = props.providers;
  var railsContext = props.railsContext;
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var providers$1 = React.useMemo((function () {
          return Belt_Array.map(providers, Provider.Simple.fromJson);
        }), [providers]);
  var projectId$1 = React.useMemo((function () {
          return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(projectId, Json_Decode$JsonCombinators.option(Project.Id.decoder)));
        }), [projectId]);
  var context = React.useMemo((function () {
          var match = Belt_Array.map(selectedProvidersIds, ID.fromJson);
          if (projectId$1 !== undefined) {
            return {
                    TAG: "Project",
                    projectId: Caml_option.valFromOption(projectId$1),
                    selectedProvidersIds: match
                  };
          } else if (match.length !== 0) {
            return {
                    TAG: "Draft",
                    selectedProvidersIds: match
                  };
          } else {
            return {
                    TAG: "Draft",
                    selectedProvidersIds: undefined
                  };
          }
        }), [
        projectId$1,
        selectedProvidersIds
      ]);
  var projectStatus = React.useMemo((function () {
          return Project.Status.fromString(status);
        }), [status]);
  return JsxRuntime.jsx(CreateBareMetalProjectProvidersSelection.make, {
              mobile: railsContext$1.mobile,
              context: context,
              providers: providers$1,
              userLoginStatus: railsContext$1.userLoginStatus,
              setUserData: props.reduxfunction.setUserData,
              projectStatus: projectStatus,
              userRole: railsContext$1.userRole
            });
}

var $$default = CreateBareMetalProjectProvidersSelection__JsBridge$default;

export {
  $$default as default,
}
/* ID Not a pure module */
